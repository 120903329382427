import { Device, DeviceType } from '../models/device';

interface DeviceMap {
    [deviceId: string]: Device;
}

/**
 * Devices can found: https://dms-360.aka.amazon.com/getAllDeviceType
 * Devices details: https://dms-360.aka.amazon.com/getAllDeviceType?deviceTypeEncryptedId=A3RMGO6LYLH7YN
 */
 export const DEVICES: DeviceMap = {
    'AB72C64C86AW2': new Device('Doppler', 'Echo', 'Headless'),
    'A7WXQPH584YP': new Device('Radar', 'Echo 2nd', 'Headless'),
    'A3FX4UWTP28V1P': new Device('Pascal', 'Echo 3rd', 'Headless'),
    'A3RMGO6LYLH7YN': new Device('Laser', 'Echo 4th', 'Headless'),
    'AKNO1N0KSFN8L': new Device('Pancake', 'Echo Dot', 'Headless'),
    'A3S5BH2HU6VAYF': new Device('Biscuit', 'Echo Dot 2nd', 'Headless'),
    'A32DOYMUN6DTXA': new Device('Donut', 'Echo Dot 3rd', 'Headless'),
    'A1RABVCI4QCIKC': new Device('Crumpet', 'Echo Dot 3rd', 'Headless'),
    'A2U21SRK4QGSE1': new Device('Brownie', 'Echo Dot 4th', 'Headless'),
    'A2H4LV5GIZ1JFT': new Device('Ganache', 'Echo Dot 4th with Clock', 'Headless'),
    'A4ZXE0RM7LQ7A': new Device('Cannoli', 'Echo Dot 5th', 'Headless'),
    'A2DS1Q2TPDJ48U': new Device('Cheesecake', 'Echo Dot 5th with Clock', 'Headless'),
    'ASQZWP4GPYUT7': new Device('Churro', 'Echo Pop', 'Headless'),
    'A2M35JJZWCQOMZ': new Device('Sonar', 'Echo Plus', 'Headless'),
    'A18O6U1UQFJ0XK': new Device('Lidar', 'Echo Plus 2nd', 'Headless'),
    'A3RBAYBE7VM004': new Device('Octave', 'Echo Studio', 'Headless'),
    'A38949IHXHRQ5P': new Device('Fox', 'Amazon Tap', 'Headless'),
    'A30YDR2MK8HMRV': new Device('Doebrite', 'Echo Dot with Clock', 'Headless'),
    'A1NL4BVLQ4L3N3': new Device('Knight', 'Echo Show', 'Multimodal'),
    'AWZZ5CVHX2CD': new Device('Bishop', 'Echo Show 2nd', 'Multimodal'),
    'A4ZP7ZC4PI6TO': new Device('Checkers', 'Echo Show 5', 'Multimodal'),
    'A1XWJRHALS1REP': new Device('Cronos', 'Echo Show 5 - 2nd Gen', 'Multimodal'),
    'A11QM4H9HGV71H': new Device('Hypnos', 'Echo Show 5 - 3rd Gen', 'Multimodal'),
    'A1Z88NGR2BK6A2': new Device('Crown', 'Echo Show 8', 'Multimodal'),
    'A15996VY63BQ2D': new Device('Athena', 'Echo Show 8 - 2nd Gen', 'Multimodal'),
    'A2UONLFQW0PADH': new Device('Rhodes', 'Echo Show 8 - 3rd Gen', 'Multimodal'),
    'AIPK7MM90V7TB': new Device('Theia', 'Echo Show 10', 'Multimodal'),
    'A1EIANJ7PNB0Q7': new Device('Hoya', 'Echo Show 15', 'Multimodal'),
    'AZANTNEUTYY6L': new Device('Sycamore', 'Echo Show 21', 'Multimodal'),
    'AQ24620N8QD5Q': new Device('Cypress', 'Echo Show 15 2nd', 'Multimodal'),
    'ADMKNMEVNL158': new Device('Galileo', 'Echo Galileo', 'Multimodal'),
    'A10A33FOX2NUBK': new Device('Rook', 'Echo Spot', 'Multimodal'),
    'A2MDL5WTJIU8SZ': new Device('Vesta', 'Amazon Astro', 'Headless'),
    'A3VRME03NAXFUB': new Device('Croissant', 'Echo Flex ', 'Headless'),
    'A1ORT4KZ23OY88': new Device('Hendrix', 'Echo Look', 'Headless'),
    'A1JJ0KFC4ZPNJ3': new Device('Cupcake', 'Echo Input', 'Headless'),
    'A2E0SNTXJVT7WK': new Device('Bueller', 'Amazon Fire TV', 'FireTV'),
    'A12GXV8XMS007S': new Device('Sloane', 'Amazon Fire TV 2nd', 'FireTV'),
    'A2GFL5ZMWNE0PX': new Device('Needle', 'Fire TV 4K and Voice Remote', 'FireTV'),
    'ADVBD696BHNV5': new Device('Montoya', 'Fire TV Stick', 'FireTV'),
    'A2LWARUGJLBYEW': new Device('Tank', 'Fire TV Stick 2nd', 'FireTV'),
    'A265XOI9586NML': new Device('Sheledon_Plus', 'Fire TV Stick 3rd Gen', 'FireTV'),
    'AKPGW064GI9HE': new Device('Mantis', 'Fire TV Stick 4K', 'FireTV'),
    'A3EVMLQTU6WL1W': new Device('Kara', 'Fire TV Stick 4K Max', 'FireTV'),
    'A31DTMEEVDDOIV': new Device('Sheledon', 'Fire TV Stick Lite', 'FireTV'),
    'A3HF4YRA2L7XGC': new Device('Stark', 'Fire TV Cube', 'FireTV'),
    'A2JKHJ0PX4J3L3': new Device('Raven', 'Fire TV Cube 2nd', 'FireTV'),
    'A1F8D55J0FWDTN': new Device('Keira', 'Fire TV Edition - Toshiba 4K', 'FireTV'),
    'AP4RS91ZQ0OOI': new Device('Joyce', 'Fire TV Edition - Insignia 4K', 'FireTV'),
    'AFF5OAL5E3DIU': new Device('Elka', 'Fire TV Edition - Insignia HD', 'FireTV'),
    'A1P7E7V3FCZKU6': new Device('Blanche/Alison', 'Fire TV Edition - Toshiba HD', 'FireTV'),
    'AGHZIK8D6X7QR': new Device('Margo', 'Fire TV Edition - Element 4K', 'FireTV'),
    'A1AGU0A4YA5RF9': new Device('Greta Plus', 'Fire TV Edition - Grundig OLED', 'FireTV'),
    'A93SQJNJQLDSS': new Device('Greta', 'Fire TV Edition - Grundig Vision 7', 'FireTV'),
    'AN630UQPG2CA4': new Device('Duckie-Corleone', 'Fire TV Edition - Toshiba/Insignia', 'FireTV'),
    'A16MZVIFVHX6P6': new Device('Puget', 'Echo Buds', 'On-The-Go'),
    'A3IYPH06PH1HRA': new Device('Zephyr', 'Echo Frames', 'On-The-Go'),
    'A15QWUTQ6FSMYX': new Device('Powell', 'Echo Buds 2nd', 'On-The-Go'),
    'A303PJF6ISQ7IC': new Device('Muffin', 'Echo Auto', 'Echo Auto'),
    'A12IZU8NMHSY5U': new Device('Acura In-Vehicle Alexa Acura MDX', 'Acura In-Vehicle Alexa Acura MDX', 'Echo Auto'),
    'A1C6Z2ZG39IG3X': new Device('Audi', 'Audi', 'Echo Auto'),
    'A1MYA62AKO9I9Q': new Device('Audi Embedded', 'Audi Embedded', 'Echo Auto'),
    'A17KNHDVUO2UVP': new Device('Audi MIB3', 'Audi MIB3', 'Echo Auto'),
    'A20Z7716PJF11S': new Device('Automobili Lamborghini', 'Automobili Lamborghini', 'Echo Auto'),
    'A319VMKX4SJMYC': new Device('BMW US', 'BMW US', 'Echo Auto'),
    'A3SQ327AQ5AD9O': new Device('BMW iD8.1 MGU22 US', 'BMW iD8.1 MGU22 US', 'Echo Auto'),
    'A28V618DSNM82M': new Device('FCA', 'FCA', 'Echo Auto'),
    'A3M91KUSTM6A3P': new Device('Ford - Alexa Built-In SDK', 'Ford - Alexa Built-In SDK', 'Echo Auto'),
    'AMOTIZE8IFT9L': new Device('GM GAS US', 'GM GAS US', 'Echo Auto'),
    'ALT9P69K6LORD': new Device('General Motors', 'General Motors', 'Echo Auto'),
    'A5IGCAJR2L9O6': new Device('Honda MY23 Accord 30AA', 'Honda MY23 Accord 30AA', 'Echo Auto'),
    'A1D6RDUOWH31HF': new Device('JLR InControl', 'JLR InControl', 'Echo Auto'),
    'AF746J4KLOSL0': new Device('Lexus 2.0', 'Lexus 2.0', 'Echo Auto'),
    'A36DOJ9I8N9OAE': new Device('Lincoln - Alexa Built-In SDK', 'Lincoln - Alexa Built-In SDK', 'Echo Auto'),
    'A3UMK5U83JWRIT': new Device('Lucid with Alexa Built-in', 'Lucid with Alexa Built-in', 'Echo Auto'),
    'A10MB9XUL7FN1Z': new Device('MY2023 Acura Integra', 'MY2023 Acura Integra', 'Echo Auto'),
    'A1G68QNNZF5OEV': new Device('Nissan AIVI2ph1', 'Nissan AIVI2ph1', 'Echo Auto'),
    'A2WJ2CM9ARLMRH': new Device('Rivian R1T Alexa Built-in', 'Rivian R1T Alexa Built-in', 'Echo Auto'),
    'A3BCYVUOMSBZQR': new Device('Stellantis R1H MY22 Alexa Built-in', 'Stellantis R1H MY22 Alexa Built-in', 'Echo Auto'),
    'AU4IFDJDRSBC1': new Device('Toyota 2.0', 'Toyota 2.0', 'Echo Auto'),
    'A2O9REW9TF2YQR': new Device('VW Embedded', 'VW Embedded', 'Echo Auto'),
    'A2J7K7SBZ5AS4': new Device('VinFast Alexa', 'VinFast Alexa', 'Echo Auto'),
    'A3MZO9I5160XD5': new Device('Volvo Trucks SEM2', 'Volvo Trucks SEM2', 'Echo Auto')
};

export const VCIF_DEVICES: DeviceMap = {
    'A1NL4BVLQ4L3N3': new Device('Knight', 'Echo Show', 'Multimodal'),
    'AWZZ5CVHX2CD': new Device('Bishop', 'Echo Show 2nd', 'Multimodal'),
    'A4ZP7ZC4PI6TO': new Device('Checkers', 'Echo Show 5', 'Multimodal'),
    'A1XWJRHALS1REP': new Device('Cronos', 'Echo Show 5 - 2nd Gen', 'Multimodal'),
    'A11QM4H9HGV71H': new Device('Hypnos', 'Echo Show 5 - 3rd Gen', 'Multimodal'),
    'A1Z88NGR2BK6A2': new Device('Crown', 'Echo Show 8', 'Multimodal'),
    'A15996VY63BQ2D': new Device('Athena', 'Echo Show 8 - 2nd Gen', 'Multimodal'),
    'A2UONLFQW0PADH': new Device('Rhodes', 'Echo Show 8 - 3rd Gen', 'Multimodal'),
    'AIPK7MM90V7TB': new Device('Theia', 'Echo Show 10', 'Multimodal'),
    'A1EIANJ7PNB0Q7': new Device('Hoya', 'Echo Show 15', 'Multimodal'),
    'AZANTNEUTYY6L': new Device('Sycamore', 'Echo Show 21', 'Multimodal'),
    'AQ24620N8QD5Q': new Device('Cypress', 'Echo Show 15 2nd', 'Multimodal')
};

export const RCP_DEVICES: DeviceMap = {
    'A1NL4BVLQ4L3N3': new Device('Knight', 'Echo Show', 'Multimodal'),
    'AWZZ5CVHX2CD': new Device('Bishop', 'Echo Show 2nd', 'Multimodal'),
    'A4ZP7ZC4PI6TO': new Device('Checkers', 'Echo Show 5', 'Multimodal'),
    'A1XWJRHALS1REP': new Device('Cronos', 'Echo Show 5 - 2nd Gen', 'Multimodal'),
    'A1Z88NGR2BK6A2': new Device('Crown', 'Echo Show 8', 'Multimodal'),
    'A15996VY63BQ2D': new Device('Athena', 'Echo Show 8 - 2nd Gen', 'Multimodal'),
    'A2UONLFQW0PADH': new Device('Rhodes', 'Echo Show 8 - 3rd Gen', 'Multimodal'),
    'AIPK7MM90V7TB': new Device('Theia', 'Echo Show 10', 'Multimodal'),
    'A1EIANJ7PNB0Q7': new Device('Hoya', 'Echo Show 15', 'Multimodal'),
    'AZANTNEUTYY6L': new Device('Sycamore', 'Echo Show 21', 'Multimodal'),
    'AQ24620N8QD5Q': new Device('Cypress', 'Echo Show 15 2nd', 'Multimodal')
};

export const getListOfDevicesInDeviceType = (deviceType: DeviceType): DeviceMap => {
    return Object.keys(DEVICES)
            .filter(deviceId => DEVICES[deviceId].type === deviceType)
            .reduce((prev: DeviceMap, cur: string) => {
                prev[cur] = DEVICES[cur];
                return prev;
            }, {});
};

export const hasOnTheGoDevices = (deviceList: string[]): boolean => {
    const onTheGoDevices = getListOfDevicesInDeviceType('On-The-Go');
    const twoArraysOverlap: string[] = Object.keys(onTheGoDevices)
            .filter(onTheGoDeviceid => deviceList.includes(onTheGoDeviceid));
    return twoArraysOverlap.length > 0;
};
